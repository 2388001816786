import { Box, Typography } from "@mui/material";
import Logo from '@assets/svg/Airbnbfirststep.svg?react';
import { RenderSuccess } from "./RenderSuccess";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useMemo, useState } from "react";
import { ToastMessageContext } from "@context/toast-message-cotnext";
import { useProperty } from "@services";

import { SelectProperties } from "./SelectedProperties";
import { LoadingWrapper } from '@components';

export const SelectChannel = ({ ConnectAirbnbWithBooking = false, onClose = () => { } }) => {
    const { t } = useTranslation();
    const searchParams = new URLSearchParams(location.search);

    const { connectToNewChannelMutate, getAirbnbListingQuery, getManagementListQuery } = useProperty();
    const {
        data: getAirbnbListing,
        isLoading: getAirbnbListingLoading,
        isError: getAirbnbListingError,
        refetch: getAirbnbListingRefetch,
        isRefetching: getAirbnbListingRefreshing
    } = getAirbnbListingQuery(localStorage.getItem('channexChannelId') ?? '');
    const {
        data: response,
        isLoading: propertiesListLoading,
        isError: propertiesListError,
        refetch: propertiesListRefetch,
    } = getManagementListQuery(1000, 1)
    const propertiesList = response?.result
    useEffect(() => {
        getAirbnbListingRefetch()
    }, []);

    const [phone, setPhone] = useState('');
    const [channels, setChannels] = useState('');
    const { showSuccess, showError } = useContext(ToastMessageContext);
    const [step, setStep] = useState(0);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const { addPropertyMutate, airbnbCreateGroupAndPropertyMutate } =
        useProperty();

  

    const { mutate: airbnbCreateGroupAndProperty } = airbnbCreateGroupAndPropertyMutate({
        onSuccess: () => { },
        onError: () => {
            showError(t('ERRORS.SOMETHING_WENT_WRONG'));
        },
    });


    const tokenExists = () => {
        let token;
        if (channels == 'gathern') {
            token = localStorage.getItem('gathernToken');
        } else if (channels == 'AIRBNB') {
            token = localStorage.getItem('channexChannelId');
        }
        return token && token != 'null';
    };

    const closeModal = () => {
        setStep(0);

        setShowConfirmation(false);
        setChannels('');
    };


    const { isSuccess, isError, isLoading: isListingLoading } = getAirbnbListingQuery(searchParams.get('channel_id') ?? '');

    useEffect(() => {
        if (searchParams.get('channel_id')) {
            let token = searchParams.get('channel_id')
            if (isSuccess && token) {
                localStorage.setItem('channexChannelId', token);
                getAirbnbListingRefetch()
                setChannels('AIRBNB');
            }

        }
    }, [searchParams.get('channel_id'), isSuccess]);


    const AirbnbUnits = getAirbnbListing?.response?.data?.listing_id_dictionary?.values
    if (
       (((AirbnbUnits?.length && channels == 'AIRBNB') || (AirbnbUnits?.length && propertiesList?.length) || ConnectAirbnbWithBooking) && !propertiesListLoading) ||
        (channels == 'AIRBNB' && !propertiesListLoading) ||
        (propertiesList?.length && !propertiesListLoading)

    ) {
        return <SelectProperties ConnectAirbnbWithBooking={ConnectAirbnbWithBooking} propertiesList={propertiesList} list={AirbnbUnits} onClose={onClose} />;
    } 
    return <LoadingWrapper loading={getAirbnbListingRefreshing || getAirbnbListingLoading || isListingLoading}>

        <Box sx={{ mx: "20%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>

            <Logo style={{ width: "300px", height: "74px", marginBottom: "10px" }} />
            <Typography sx={{ fontWeight: 700, fontSize: "24px", lineHeight: "28px" }}>
                {t('PROPERTIES_MANAGEMENT.ONE_STEP_LINKING')}
            </Typography>
            <Typography sx={{ fontWeight: 400, fontSize: "14px", lineHeight: "16.8px", color: "#625783", mt: 2 }}>
                {t('PROPERTIES_MANAGEMENT.CAN_MANAGE_WITH_MASSOL')}
            </Typography>
            <Box onClick={() => {
                if (!tokenExists() && !AirbnbUnits?.length) {
                    return airbnbCreateGroupAndProperty()
                }
                setChannels('AIRBNB');


            }} sx={{ width: "200px", height: "41px", borderRadius: "8px", backgroundColor: "#5B3FFF", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer", mt: 2, mb: 2 }}>
                <Typography sx={{ color: "#FFFFFF", fontWeight: 700, fontSize: "14px", lineHeight: "16.8px" }}>
                    {t('PROPERTIES_MANAGEMENT.LINK_ACCOUNT')}
                </Typography>
            </Box>

            <RenderSuccess title={t('PROPERTIES_MANAGEMENT.AUTO_SYNC_BOOKINGS')} />
            <RenderSuccess title={t('PROPERTIES_MANAGEMENT.CALENDER_EASY')} />
            <RenderSuccess title={t('PROPERTIES_MANAGEMENT.FOLLOW_FINANCE')} />
            <RenderSuccess title={t('PROPERTIES_MANAGEMENT.LIVE_UPDATES')} />
        </Box>
    </LoadingWrapper>
} 