import { Box, Typography } from "@mui/material";
import Logo from '@assets/svg/Airbnbfirststep.svg?react';
import Mouse from '@assets/svg/Mousecircle.svg?react';
import { useTranslation } from "react-i18next";



export const LoadingState = () => {
    const { t } = useTranslation();
    return <Box sx={{ mx: "20%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
        <Logo style={{ width: "160px", height: "74px", marginBottom: "10px" }} />
        <Typography sx={{ fontWeight: 700, fontSize: "24px", lineHeight: "28.8px", color: "#331860" }}>
            {t('PROPERTIES_MANAGEMENT.PROGRESS_CONNECT')}
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: "14px", lineHeight: "16.8px", color: "#625783", mt: 2 }}>
            {t('PROPERTIES_MANAGEMENT.FOLLOW_YOUR_BOOKING')}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: "20px" }}>
            <Mouse />
            <Typography sx={{ marginRight: "5px", color: "#435363", fontSize: "12px", lineHeight: "14.4px", fontWeight: "400" }}>
                {t('PROPERTIES_MANAGEMENT.ONE_CLICK_OPERATION')}
            </Typography>
        </Box>
    </Box>
}