import {
	DateSelectArg,
	DateSpanApi,
	DatesSetArg,
	EventInput,
} from "@fullcalendar/core"
import FullCalendar from "@fullcalendar/react"
import interactionPlugin from "@fullcalendar/interaction" // for drag and drop, click, etc.
import resourceTimelinePlugin from "@fullcalendar/resource-timeline" // needed for dayClick
import {
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react"
import { useTranslation } from "react-i18next"
import { CalendarComponentsWrapper } from "./styles"
import CustomViewProperties from "./components/customViewProperties"
import CustomSearchInput from "./components/customSearchInput"
import PaginationProperties from "./components/paginationProperties"
import EventContent from "./components/eventContent"
import CustomHeader from "./components/customHeader"
import CustomSlotLabelCalendar from "./components/customSlotLabelCalendar"
import dayGridPlugin from "@fullcalendar/daygrid"
import CustomDayCellContent from "./components/customDayCellContent"
import { useGetCalenderData, useGetListingManagement } from "@services"
import { useRecoilState, useRecoilValue } from "recoil"
import {
	CurrentPageStore,
	loaderState,
	NewCalenderPageCount,
	PopupOverlayNewCalender,
	searchByPropertyName,
	selectedProperty,
	switchReservationsState,
} from "@store/index"
import CustomLoading from "../CustomLoading"
import PopupOverlay from "./components/popupOverlay"
import dayjs from "dayjs"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import DayHeaderContent from "./components/dayHeaderContent"
import OtaLinker from "../../pages/PropertiesManagement/Linking/OtaLinker"

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

export default function NewCalendarComponents() {
	/* ------------------------------- local State ------------------------------ */
	const [resourcesPerPage] = useState(8)
	const [currentPage, setCurrentPage] = useRecoilState(CurrentPageStore) // State to track current page
	const [isloaderState, setLoaderState] = useRecoilState(loaderState)
	const [isPopupOverlayNewCalender, setPopupOverlayNewCalender] =
		useRecoilState(PopupOverlayNewCalender)
	const pageCount = useRecoilValue(NewCalenderPageCount)
	const [calendarTitle, setCalendarTitle] = useState<string>("")
	const [switchView, setSwitchView] = useState<
		"dayGridMonth" | "resourceTimelineWeek"
	>("resourceTimelineWeek")
	const valueSelectedProperty = useRecoilValue(selectedProperty)
	const searchByPropertyNameValue = useRecoilValue(searchByPropertyName)
	const switchReservationsStateValue = useRecoilValue(switchReservationsState)
	/* ---------------------------------- API'S --------------------------------- */
	const { data: getListingManagement } = useGetListingManagement()
	const { data: getCalenderData, isLoading } = useGetCalenderData(
		{
			keyword: switchView === "resourceTimelineWeek" ? "week" : undefined,
			page: pageCount,
			filter:
				switchReservationsStateValue === "all"
					? undefined
					: switchReservationsStateValue,
			search:
				switchView === "dayGridMonth" &&
				typeof valueSelectedProperty?.label === "string"
					? valueSelectedProperty.label
					: switchView === "resourceTimelineWeek" &&
						  typeof searchByPropertyNameValue === "string"
						? searchByPropertyNameValue
						: undefined,
			pageSize:
				switchView === "resourceTimelineWeek" ? resourcesPerPage : undefined,
			pageNumber:
				switchView === "resourceTimelineWeek" ? currentPage : undefined,
		},
		{
			enabled:
				switchView === "dayGridMonth" && valueSelectedProperty?.label
					? true
					: switchView === "resourceTimelineWeek"
						? true
						: false,
		}
	)
	/* -------------------------------- Functions ------------------------------- */
	const dataList = useMemo(() => {
		const data = getListingManagement?.result.map((item) => {
			return {
				value: item.propertyId,
				label: item.propertyName,
				connectedChannels: item.connectedChannels,
			}
		})
		return data ?? []
	}, [getListingManagement])

	const getChannelBypropertyId = useCallback(
		(propertyId: string) => {
			if (!propertyId) return
			const getMypropertyById = dataList?.filter(
				(item) => item.value.toString() === propertyId
			)
			const channelsWeeklyView = getMypropertyById.flatMap(
				(item) => item.connectedChannels
			)
			const defineMyData = channelsWeeklyView.map((item) => item.channelName)
			return defineMyData ?? []
		},
		[dataList]
	)

	const events: EventInput = useMemo(() => {
		const data = getCalenderData?.result.flatMap((item) => item.dates)
		const eventsList = data?.map((item) => {
			return {
				resourceId: item.propertyId,
				start: item.bookingInfo?.checkIn
					? item.bookingInfo?.checkIn
					: item.date,
				end: item.bookingInfo?.checkOut
					? item.bookingInfo?.checkOut
					: item.date,
				display: !item.bookingInfo?.propertyId ? "background" : null,
				extendedProps: item.bookingInfo
					? {
							isAutoAccess: getCalenderData?.result?.filter(
								(subItem) => subItem.propertyId === item.propertyId
							)[0]?.isAutoAccess,
							date: item.date,
							id: item.bookingInfo?.id,
							propertyId: item.propertyId,
							propertyName: item.bookingInfo?.propertyName,
							channelName: item.bookingInfo?.channelName,
							title: item.bookingInfo?.guestName,
							guestPhoneNumber: item.bookingInfo?.guestPhoneNumber,
							numberOfNights: item.bookingInfo?.numberOfNights,
							totalPrice: item.bookingInfo?.totalPrice,
							price: getChannelBypropertyId(
								item?.propertyId.toString()
							)?.includes("Airbnb")
								? item.airbnb_daily_price
								: getChannelBypropertyId(item?.propertyId.toString())?.includes(
											"GatherIn"
									  )
									? item.gathren_daily_price
									: item.msool_daily_price,
							bookingStatus: item.bookingInfo?.bookingStatus,
							checkIn: item.bookingInfo?.checkIn,
							checkOut: item.bookingInfo?.checkOut,
							reservationId: item.bookingInfo?.reservationId,
							availability_type: item?.availability_type,
							isMessageSend: item?.bookingInfo?.isMessageSend,
							isAbleToSendMessage: item?.bookingInfo?.isAbleToSendMessage,
						}
					: {
							isAutoAccess:
								getCalenderData?.result?.filter(
									(subItem) => subItem.propertyId === item.propertyId
								)[0]?.isAutoAccess ?? false,
							propertyId: item.propertyId,
							date: item.date,
							price: getChannelBypropertyId(
								item?.propertyId.toString()
							)?.includes("Airbnb")
								? item.airbnb_daily_price
								: getChannelBypropertyId(item?.propertyId.toString())?.includes(
											"GatherIn"
									  )
									? item.gathren_daily_price
									: item.msool_daily_price,

							priceSaturday: getChannelBypropertyId(
								item?.propertyId.toString()
							)?.includes("GatherIn")
								? item.gathren_daily_price
								: getChannelBypropertyId(item?.propertyId.toString())?.includes(
											"Airbnb"
									  )
									? item.airbnb_daily_price
									: item.msool_daily_price,
						},
			}
		})
		return eventsList ?? []
	}, [getCalenderData, valueSelectedProperty])

	const disabledDates = useMemo(() => {
		const data = getCalenderData?.result.flatMap((item) => item.dates)
		const getDataFilter = data?.filter(
			(item) =>
				item.availability === "unavailable" && item.availability_type === "busy"
		)
		const daysUnavailable = getDataFilter?.map((item) => item.date)
		return daysUnavailable ?? []
	}, [getCalenderData])

	const resources = useMemo(() => {
		const data = getCalenderData?.result.map((item) => {
			return {
				id: item.propertyId.toString(),
				extendedProps: {
					id: item.propertyId.toString(),
					title: item.propertyName,
					image: item.propertyImage,
				},
			}
		})
		return data ?? []
	}, [getCalenderData])
	/* -------------------------------- Functions ------------------------------- */

	/* --------------------------------- hotfix --------------------------------- */
	const disabledDatesHotfix = useMemo(() => {
		const data = getCalenderData?.result.flatMap((item) => item.dates)
		const getDataFilter = data?.filter(
			(item) =>
				item.availability === "unavailable" && item.availability_type === "busy"
		)
		const daysUnavailable = getDataFilter?.map((item) => {
			return { date: item?.date?.toString(), id: item?.propertyId?.toString() }
		})
		return daysUnavailable ?? []
	}, [getCalenderData])

	const isBlockDay = (theDate: string, theId: string) => {
		const validateDate = disabledDatesHotfix.some(
			(item) => item?.date === theDate && item?.id === theId
		)
		return validateDate
	}
	/* --------------------------------- hotfix --------------------------------- */
	function handleDateSelect(selectInfo: DateSelectArg) {
		const selectedId = selectInfo.resource?.extendedProps.id
		const filterDataWeeklyView = events.filter(
			(item: any) =>
				dayjs(item.start).day() === 6 &&
				selectedId === item.resourceId.toString()
		)
		const filterDataWeeklyMonthlyView = events.filter(
			(item: any) => dayjs(item.start).day() === 6
		)
		const filteredDaysMonthlyView =
			filterDataWeeklyMonthlyView[filterDataWeeklyMonthlyView.length - 1]
		const filteredDaysWeeklyView = filterDataWeeklyView

		const weeklyView = events.filter((event: any) => {
			const eventDate = dayjs(event.start)
			return (
				eventDate?.isSameOrAfter(
					dayjs(selectInfo.start).format("YYYY-MM-DD")
				) &&
				eventDate?.isSameOrBefore(
					dayjs(selectInfo.end).subtract(1, "day").format("YYYY-MM-DD")
				) &&
				selectedId === event.resourceId.toString()
			)
		})
		const monthlyView = events.filter((event: any) => {
			const eventDate = dayjs(event.start)
			return (
				eventDate?.isSameOrAfter(
					dayjs(selectInfo.start).format("YYYY-MM-DD")
				) &&
				eventDate?.isSameOrBefore(
					dayjs(selectInfo.end).subtract(1, "day").format("YYYY-MM-DD")
				)
			)
		})
		const allEventsBetweenFromAndToDate =
			switchView === "resourceTimelineWeek" ? weeklyView : monthlyView
		const getAllPrice = allEventsBetweenFromAndToDate.map((item: any) => {
			return item.extendedProps.price
		})
		const uniq = [...new Set(getAllPrice)]
		const filteredArr = uniq.filter(
			(item) => item !== null && item !== undefined
		)
		const sortedPriceArr = filteredArr.sort((a: any, b: any) => a - b)

		setPopupOverlayNewCalender({
			isOpen: true,
			openModule: "advancedSetting",
			from: dayjs(selectInfo.start),
			to: dayjs(selectInfo.end),
			isAvailable: !selectedId
				? !disabledDates.includes(
						dayjs(selectInfo.startStr).format("YYYY-MM-DD")
					)
				: !isBlockDay(
						dayjs(selectInfo.startStr).format("YYYY-MM-DD"),
						selectedId
					),

			price: sortedPriceArr as number[],
			saturdayPrice:
				switchView === "dayGridMonth"
					? filteredDaysMonthlyView?.extendedProps?.priceSaturday?.toString() ??
						filteredDaysMonthlyView?.extendedProps?.price?.toString()
					: filteredDaysWeeklyView[0]?.extendedProps?.priceSaturday?.toString() ??
						filteredDaysWeeklyView[0]?.extendedProps?.price?.toString(),
			propertyValue:
				switchView === "dayGridMonth"
					? valueSelectedProperty
					: {
							value: selectInfo?.resource?._resource?.extendedProps?.id,
							label: selectInfo?.resource?._resource?.extendedProps?.title,
							connectedChannels: [],
						},
		})
	}
	const handleDatesSet = (info: DatesSetArg) => {
		const newTitle = info.view.title // Get the title from the event info
		setCalendarTitle(newTitle) // Update the title state
	}

	const selectAllow = (selectInfo: DateSpanApi) => {
		const isFutureDate = dayjs(selectInfo.start).isSameOrAfter(dayjs(), "day")
		const startDate = dayjs(selectInfo.start).format("YYYY-MM-DD") + "T12:00"
		const propertyId = selectInfo.resource?.extendedProps.id
		const selectedCellId = events.filter(
			(item: any) =>
				item.extendedProps.checkIn === startDate &&
				item.extendedProps.propertyId.toString() === propertyId
		)
		const selectedCell = events.filter(
			(item: any) =>
				item.extendedProps.checkIn &&
				item.extendedProps.checkOut &&
				selectedCellId[0]?.resourceId.toString() === propertyId &&
				dayjs(startDate).isBetween(
					item.extendedProps.checkIn,
					dayjs(item.extendedProps.checkOut).subtract(1, "day"),
					"day",
					"[]"
				)
		)
		const isDateAvailable = selectedCell.length === 0
		return isFutureDate && isDateAvailable
	}
	/* ---------------------------------- Hooks --------------------------------- */
	const calendarRef = useRef<FullCalendar | null>(null)

	const {
		i18n: { language },
	} = useTranslation()

	const refreshCalendar = () => {
		const calendarApi = calendarRef.current?.getApi()
		if (calendarApi) {
			calendarApi.changeView(switchView) // Re-render the calendar view
		}
	}

	useEffect(() => {
		if (switchView) {
			refreshCalendar()
		}
	}, [switchView, refreshCalendar])

	useEffect(() => {
		setLoaderState(isLoading)
	}, [isLoading, setLoaderState])

	useEffect(() => {
		setPopupOverlayNewCalender(undefined)
	}, [setPopupOverlayNewCalender])
	return (
		<Fragment>
			{getCalenderData?.result && getCalenderData?.result.length > 0 ? (
				<CalendarComponentsWrapper switchView={switchView}>
					{isloaderState && <CustomLoading />}
					{isPopupOverlayNewCalender?.isOpen && <PopupOverlay />}
					<CustomHeader
						calendarRef={calendarRef}
						switchView={switchView}
						setSwitchView={setSwitchView}
						calendarTitle={calendarTitle}
					/>
					<FullCalendar
						schedulerLicenseKey='0454582308-fcs-1728214716'
						locale={language}
						ref={calendarRef}
						datesSet={handleDatesSet} // Triggered whenever the dates are set (e.g., after navigating)
						timeZone='utc'
						initialDate={dayjs(new Date()).format("YYYY-MM-DD")}
						direction={language === "ar" ? "rtl" : "ltr"}
						plugins={[resourceTimelinePlugin, interactionPlugin, dayGridPlugin]}
						initialView={switchView} // Displays the week in resource timeline
						firstDay={new Date().getDay()}
						// visibleRange={getCurrentWeekVisibleRange()} // Displays current week's range (Monday-Sunday)
						resources={resources} // all properties
						events={events}
						resourceAreaWidth={200} // Set a fixed width for the resource column
						slotLabelFormat={{ weekday: "long", day: "numeric" }} // Displays days in the header
						slotLabelContent={(info) => <CustomSlotLabelCalendar info={info} />}
						slotDuration='24:00' // Each slot is 1 day
						height='auto'
						headerToolbar={false} // Hide default header
						dayCellContent={(info) => (
							<CustomDayCellContent info={info} disabledDates={disabledDates} />
						)}
						dayHeaderContent={(info) => <DayHeaderContent info={info} />}
						eventContent={(arg) => (
							<EventContent
								id={arg?.event.extendedProps?.id}
								isAutoAccess={arg?.event?.extendedProps?.isAutoAccess}
								getCalenderData={getCalenderData}
								date={arg.event?.extendedProps?.date}
								display={arg?.event.display as "background" | null}
								cellPrice={arg.event?.extendedProps?.price}
								bookingStatus={
									arg?.event.extendedProps?.bookingStatus as
										| "مؤكد"
										| "new"
										| "cancelled"
										| "ملغي"
								}
								title={arg?.event.extendedProps?.title}
								totalPrice={arg?.event.extendedProps?.totalPrice}
								channelName={
									arg?.event.extendedProps?.channelName as
										| "Airbnb"
										| "GatherIn"
										| "Booking"
										| "Agoda"
										| "Msool"
								}
								checkIn={arg.event.extendedProps.checkIn}
								checkOut={arg.event.extendedProps.checkOut}
								guestPhoneNumber={arg.event.extendedProps.guestPhoneNumber}
								propertyId={arg.event.extendedProps.propertyId}
								propertyName={arg.event.extendedProps.propertyName}
								reservationId={arg.event.extendedProps.reservationId}
								availability_type={arg.event.extendedProps.availability_type}
								isMessageSend={arg.event.extendedProps.isMessageSend}
								isAbleToSendMessage={
									arg.event.extendedProps.isAbleToSendMessage
								}
							/>
						)} //Event Content
						eventColor='transparent' // Global background color for all events
						resourceAreaColumns={[
							{
								headerContent: () => <CustomSearchInput />, // Custom search input
								cellContent: (arg) => <CustomViewProperties arg={arg} />, // Custom view properties
							},
						]}
						select={handleDateSelect} // Enable selection
						selectable={true}
						selectMirror={true}
						dayMaxEvents={true}
						selectAllow={(selectInfo) => selectAllow(selectInfo)}
					/>
					{switchView === "resourceTimelineWeek" && (
						<PaginationProperties
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							resourcesPerPage={resourcesPerPage}
							lengthOfRecords={getCalenderData?.totalRecords ?? 0}
						/>
					)}
				</CalendarComponentsWrapper>
			) : (
				<OtaLinker />
			)}
		</Fragment>
	)
}
