import { Box, Typography } from "@mui/material";
import Market from '@assets/svg/Sucessconnections.svg?react';

export const RenderSuccess = ({ title = "" }) => {
    return <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mb: "10px" }}>
        <Market />
        <Typography sx={{ marginRight: "5px", color: "#5B3FFF", fontSize: "12px", lineHeight: "14.4px", fontWeight: "400" }}>
            {title}
        </Typography>
    </Box>
}

