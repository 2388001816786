import { Box, Typography } from "@mui/material";
import Market from '@assets/svg/Sucessconnections.svg?react';
import BookingLogo from '@assets/svg/BookingLogo.svg?react';
import Gazern from '@assets/svg/Gazern.svg?react';
import { RenderSuccess } from "./RenderSuccess";
import { useTranslation } from "react-i18next";
import { memo, useState } from "react";
import { BookingLinkingStep } from "./BookingLinkingStep";
import { BookingEnterID } from "./BookingEnterID";
import { SuccessLinking } from "./SuccessLinking";



export const ConnectWithOtherConnectorsPure = ({ onClose = () => { }, ConnectAirbnbWithBooking = false, propertiesList = [], isSelected = false, list = [] }: any) => {
    const { t } = useTranslation();
    const [showBookingConnector, setShowBookingConnector] = useState(false)
    const [showEnterId, setShowEnterId] = useState(false)
    const [showSuccessPage, setShowSuccessPage] = useState(false)
    const [selectedChannel, setSelectedChannel] = useState({
        booking: false,
        gatherin: false
    })

    if (showSuccessPage) {
        return <SuccessLinking onClose={onClose} />;
    }
    if (ConnectAirbnbWithBooking || showEnterId) {

        return <BookingEnterID onClose={onClose} propertiesList={propertiesList} list={list} onNext={() => setShowSuccessPage(true)} />
    }
    if (showBookingConnector) {

        return <BookingLinkingStep onComplete={() => setShowEnterId(true)} /> 

    }
    return <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "start", border: "1px solid #16AE26", backgroundColor: "#16AE260A", borderRadius: "12px", padding: "16px" }}>
                <Market style={{ width: "38px", height: "38px" }} />
                <Box sx={{ mx: 2 }}>
                    <Typography sx={{ fontWeight: 700, fontSize: "20px", lineHeight: "20px", color: "#331860", mb: 1 }}>
                        {t('PROPERTIES_MANAGEMENT.CONNECTED_SUCCESS_PROPERTY')}
                    </Typography>
                    <Typography sx={{ fontWeight: 400, fontSize: "14px", lineHeight: "16.8px", color: "#625783" }}>
                        {t('PROPERTIES_MANAGEMENT.READY_TO_MANAGEMENT_AIRBNB')}
                    </Typography>
                </Box>
            </Box>

            <Box sx={{ mt: 2 }}>
                <Typography sx={{ fontWeight: 700, fontSize: "24px", lineHeight: "28.8px", color: "#331860", mb: 1 }}>
                    {t('PROPERTIES_MANAGEMENT.WANT_TO_DOUBLE')}
                </Typography>
                <Typography sx={{ fontWeight: 400, fontSize: "14px", lineHeight: "16.8px", color: "#625783", textAlign: "center" }}>
                    {t('PROPERTIES_MANAGEMENT.INCREASE_REACH')}
                </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: 2 }}>
                <Box onClick={() => {
                    setSelectedChannel({
                        booking: true,
                        gatherin: false
                    })
                }} sx={{ cursor: 'pointer', width: "199.5px", height: "98px", borderRadius: "12px", padding: "30px 24px 20px", border: selectedChannel.booking ? "1px solid #5B3FFF" : "1px solid #E2E8F0" }}>
                    <BookingLogo style={{ width: "28px" }} />
                    <Typography sx={{ fontWeight: 700, fontSize: "16px", lineHeight: "19.2px", color: "#331860" }}>
                        Booking.com
                    </Typography>
                </Box>
                <Box
                    onClick={() => {
                        setSelectedChannel({
                            booking: false,
                            gatherin: true
                        })
                    }}
                    sx={{ cursor: 'pointer', width: "199.5px", height: "98px", borderRadius: "12px", padding: "30px 24px 20px", border: selectedChannel.gatherin ? "1px solid #5B3FFF" : "1px solid #E2E8F0", marginRight: "10px" }}>
                    <Gazern style={{ width: "28px" }} />
                    <Typography sx={{ fontWeight: 700, fontSize: "16px", lineHeight: "19.2px", color: "#331860" }}>
                        جاذرن
                    </Typography>
                </Box>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: 2 }}>
                <Box onClick={() => {
                    if (selectedChannel.booking == false) return
                    setShowBookingConnector(true)
                }} sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: 3 }}>
                    <Box sx={{ width: "200px", height: "41px", borderRadius: "8px", backgroundColor:!selectedChannel.booking ?'gray': "#5B3FFF", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                        <Typography sx={{ color: "#FFFFFF", fontWeight: 700, fontSize: "14px", lineHeight: "16.8px" }}>
                            {t('PROPERTIES_MANAGEMENT.NEW_LINK')}
                        </Typography>
                    </Box>
                </Box>
                <Box onClick={onClose} sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: 3, marginRight: "10px", cursor: 'pointer' }}>
                    <Box sx={{ width: "200px", height: "41px", borderRadius: "8px", backgroundColor: "#FFFFFF", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                        <Typography sx={{ color: "#5B3FFF", fontWeight: 700, fontSize: "14px", lineHeight: "16.8px" }}>
                            {t('PROPERTIES_MANAGEMENT.SKIP')}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Box sx={{ mt: 2 }}>
                <RenderSuccess title={t('PROPERTIES_MANAGEMENT.ONE_MANAGMENT')} />
                <RenderSuccess title={t('PROPERTIES_MANAGEMENT.AUTO_SYNC_DATE_PRICE')} />
                <RenderSuccess title={t('PROPERTIES_MANAGEMENT.ONE_PLACE_CONTROL')} />
            </Box>
        </Box>

    </Box>
}
export const ConnectWithOtherConnectors = memo(ConnectWithOtherConnectorsPure,()=>true)