import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";




export const RenderProperty = ({ image = "", name = "شقة مميزة في النرجس", location = "42 شارع الأمير مقرن، المرسلات، الرياض", isSelected = false, onClick = () => { } }) => {

    const { t } = useTranslation();

    return <Box sx={{ borderRadius: isSelected ? "12px" : undefined, border: isSelected ? "0.71px solid #5B3FFF" : undefined, padding: "5.68px", mx: 1 }}>
        {/* onClick= () => { } */}
        <Box
        component="img"
        sx={{
            width: 173.12,
            height: 'auto',
            borderRadius: "5.68px",
        }}
        alt="Description of image"
        src={image}
        />
        <Typography sx={{ width: "173.12px", fontWeight: "700", fontSize: "9.94px", lineHeight: "11.93px", color: "#331860" }}>
            {name}
        </Typography>
        <Typography sx={{ fontWeight: "400", fontSize: "8.52px", lineHeight: "10.22px", color: "#625783" }}>
            {location}
        </Typography>
    </Box>
}