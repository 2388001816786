import { useTranslation } from "react-i18next"
import { BannerSectionWrapper } from "./styles"
import { GoInfo } from "react-icons/go"
import { useRecoilValue } from "recoil"
import { userDetailsStore } from "@store/userDetail"
import { useGetActivePlan, useGetCheckoutURL } from "@services"
import { useEffect } from "react"

export default function BannerSection() {
	const userDetailsValue = useRecoilValue(userDetailsStore)
	const {
		t,
		i18n: { language },
	} = useTranslation()

	const { data: dataActivePlan } = useGetActivePlan(
		{
			lang: language,
		},
		{ enabled: language !== undefined }
	)
	const { data: checkoutData, refetch: refetchDataCheckoutURL } =
		useGetCheckoutURL(
			{
				userId: dataActivePlan?.result.userId.toString()!,
				selectedPlanId: dataActivePlan?.result.subscriptionPlanId.toString()!,
				initNumberOfSeat: dataActivePlan?.result.numberOfSeat.toString()!,
			},
			{
				enabled: false,
			}
		)

	useEffect(() => {
		if (checkoutData?.result?.checkout_url) {
			window.open(checkoutData?.result?.checkout_url, "_self")
		}
	}, [checkoutData])
	return (
		<BannerSectionWrapper>
			<div className='containerMessage'>
				<span className='icon'>
					<GoInfo />
				</span>
				<span className='infoText'>
					{language === "ar"
						? userDetailsValue.result.popUpMessage.contentAr
						: userDetailsValue.result.popUpMessage.contentEn}
				</span>
			</div>
			<div className='containerActions'>
				<div className='buttonPay' onClick={() => refetchDataCheckoutURL()}>
					{t("MANAGE_PLAN.PAY_NOW")}
				</div>
			</div>
		</BannerSectionWrapper>
	)
}
