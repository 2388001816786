import { Box, Typography } from "@mui/material";
import BookingStepOne from '@assets/svg/BookingStepOne.svg?react';
import BookingStepTwo from '@assets/svg/BookingStepTwo.svg?react';
import BookingStepThree from '@assets/svg/BookingStepThree.svg?react';
import BookingStepFour from '@assets/svg/BookingStepFour.svg?react';
import { useState } from 'react';
import { useTranslation } from "react-i18next";

interface RenderBookingPropertyProps {
    activeIndex: number;
}

interface StepContent {
    title: string;
    desc: string;
}

const RenderBookingProperty = ({ activeIndex }: RenderBookingPropertyProps) => {
    const bookingSteps = [
        { id: 1, Component: BookingStepOne },
        { id: 2, Component: BookingStepTwo },
        { id: 3, Component: BookingStepThree },
        { id: 4, Component: BookingStepFour },
    ];
    return <Box sx={{ backgroundColor: "#FAFAFB", width: "565.18px", height: "300px", borderRadius: "14.29px", display: "flex", justifyContent: "center", mt: 6, mx: "10%", mb: 2, position: "relative", overflow: "hidden" }}>
        <Box sx={{ position: "absolute", top: "50px" }}>
            {bookingSteps.map(({ id, Component }, index) => (
                <Box
                    key={id}
                    sx={{
                        textAlign: 'center',
                        margin: '0 10px',
                        display: index === activeIndex ? 'block' : 'none'
                    }}
                >
                    <Component style={{ width: "527.78px", height: "252.68px", borderRadius: "5.55px" }} />
                </Box>
            ))}
        </Box>
    </Box>
}


export const BookingLinkingStep = ({ button = "التالي", onComplete = () => { } }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const bullets = [0, 1, 2, 3];
    const { t } = useTranslation();

    const stepsContent: StepContent[] = [
        {
            title: t('PROPERTIES_MANAGEMENT.STEP_ONE'),
            desc: t('PROPERTIES_MANAGEMENT.STEP_ONE_DESC')
        },
        {
            title: t('PROPERTIES_MANAGEMENT.STEP_TWO'),
            desc: t('PROPERTIES_MANAGEMENT.STEP_TWO_DESC')
        },
        {
            title: t('PROPERTIES_MANAGEMENT.STEP_THREE'),
            desc: t('PROPERTIES_MANAGEMENT.STEP_THREE_DESC')
        },
        {
            title: t('PROPERTIES_MANAGEMENT.STEP_FOUR'),
            desc: t('PROPERTIES_MANAGEMENT.STEP_FOUR_DESC')
        }
    ];

    const getButtonText = (index: number) => {
        switch (index) {
            case 0:
                return t('PROPERTIES_MANAGEMENT.NEXT');
            case 1:
            case 2:
                return t('PROPERTIES_MANAGEMENT.Connect_New_CHANNEL');
            case 3:
                return t('PROPERTIES_MANAGEMENT.NEXT');
            default:
                return t('PROPERTIES_MANAGEMENT.NEXT');
        }
    };

    const handleNext = () => {
        if (activeIndex === bullets.length - 1) {
            onComplete();
            setActiveIndex(0);
            return;
        }

        if (activeIndex < bullets.length - 1) {
            setActiveIndex(prev => prev + 1);
        }
    };

    return <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Box>
            <Typography sx={{ fontWeight: 700, fontSize: "24px", lineHeight: "28.8px", color: "#331860" }}>
                {t('PROPERTIES_MANAGEMENT.FOLLOW_STEPS_TO_CONNECT')}
            </Typography>
            <Typography sx={{ fontWeight: 400, fontSize: "14px", lineHeight: "16.8px", color: "#625783", mt: 1 }}>
                {t('PROPERTIES_MANAGEMENT.STEP_BOOKING')}
            </Typography>
            <RenderBookingProperty activeIndex={activeIndex} />
            <Box display="flex" justifyContent="center" alignItems="center">
                {bullets.map((bullet, index) => (
                    <Box
                        key={index}
                        onClick={() => setActiveIndex(index)}
                        sx={{
                            width: 12,
                            height: 12,
                            borderRadius: '50%',
                            backgroundColor: activeIndex === index ? '#5A39D7' : '#d3d3d3',
                            margin: '0 4px',
                            cursor: 'pointer',
                        }}
                    />
                ))}
            </Box>
            <Typography sx={{ fontWeight: 400, fontSize: "16.65px", lineHeight: "19.98px", color: "#0F172A", mt: 1 }}>
                {stepsContent[activeIndex].title}
            </Typography>
            <Typography sx={{ fontWeight: 400, fontSize: "14.8px", lineHeight: "17.7px", color: "#8E98A1", mt: 1 }}>
                {stepsContent[activeIndex].desc}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mx: "20%", mt: 3 }}>
                <Box
                    onClick={handleNext}
                    sx={{ width: "200px", height: "41px", borderRadius: "8px", backgroundColor: "#5B3FFF", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}
                >
                    <Typography sx={{ color: "#FFFFFF", fontWeight: 700, fontSize: "14px", lineHeight: "16.8px" }}>
                        {getButtonText(activeIndex)}
                    </Typography>
                </Box>
            </Box>
        </Box>

    </Box>
}