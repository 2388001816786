import { Box, Typography } from "@mui/material";
import { RenderProperty } from "./RenderProperty";
import { RenderSuccess } from "./RenderSuccess";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { useProperty } from "@services";
import { useIsMutating } from "@tanstack/react-query";
import { LoadingState } from "./LoadingState";
import { ConnectWithOtherConnectors } from "./ConnectWithOtherConnectors";
import { ToastMessageContext } from "@context/toast-message-cotnext";

interface ISelect {
    list: any,
    propertiesList: any,
    ConnectAirbnbWithBooking: boolean
    onClose: () => void
}
const StaticImage = "https://a0.muscache.com/im/pictures/miso/Hosting-1113684001326676721/original/0b7671c8-41ae-4d31-8db3-d9ee6d7d29be.jpeg?aki_policy=x_medium";

export const SelectProperties = ({ onClose = () => { }, ConnectAirbnbWithBooking = false, list = [], propertiesList = [] }: ISelect) => {
    const [selectedProperty, setSelectedProperty] = useState<any[]>([]);
    const { addPropertyMutate } = useProperty();
    const { showError } = useContext(ToastMessageContext);


    const { t } = useTranslation();
    const { mutate: addProperty, isError, isSuccess, isPending, reset } = addPropertyMutate({
        onError: () => {
            showError(t('ERRORS.SOMETHING_WENT_WRONG'));

        }
    });
    useEffect(() => {
        reset()
    }, [])
    const onLink = (properties: any[]) => {
        const payload: any = properties?.map((property) => ({
            propertyLocation: property?.city,
            propertyId: 0,
            propertyName: property?.title,
            propertyIsDeteled: false,
            propertyCreateDate: null,
            gathrenUnitId: '',
            airbnbUnitId: `${property?.id}`,
            autoAccess: false,
            status: true,
            templateId: null,
            propertyImage: property?.image,
        }));
        addProperty(payload);
    };
    const isSelecedProperty = (id: any) => {
        return selectedProperty?.some((s) => s.id == id);
    };
    if (isPending) {
        return <LoadingState />
    }

    if (list?.length == list?.filter((x: any) => x?.isConncted)?.length || ConnectAirbnbWithBooking) {
        return <ConnectWithOtherConnectors onClose={onClose} ConnectAirbnbWithBooking={ConnectAirbnbWithBooking} propertiesList={propertiesList} list={list} />
    }
    if (isSuccess) {
        return <ConnectWithOtherConnectors onClose={onClose} propertiesList={propertiesList} list={list} /> 
    }
    return <Box sx={{ mx: "20%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
        <Typography sx={{ fontWeight: 700, fontSize: "24px", lineHeight: "28.8px", color: "#331860" }}>
            {t('PROPERTIES_MANAGEMENT.CHOOSE_PROPERTIES')}
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: "14px", lineHeight: "16.8px", color: "#625783", mt: 1 }}>
            {t('PROPERTIES_MANAGEMENT.CHOOSE_MANAGEMENT_PROPERTIES')}
        </Typography>

        <Typography sx={{ fontWeight: "400", fontSize: "12px", lineHeight: "14.4px", color: "#5B3FFF", mt: 4 }}>
            {/* API?? */}
            {/* {t('PROPERTIES_MANAGEMENT.NO_PROPERTIES')} */}
        </Typography>
        <Box sx={{ width: "114px", height: "32px", borderRadius: "40px", backgroundColor: "#FFFFFF", border: "1px solid #21212613", display: "flex", alignItems: "center", justifyContent: "center", mt: 2, mb: 2 }}>
            <input
                checked={selectedProperty?.length === list?.length}
                onChange={() => {
                    setSelectedProperty(selectedProperty?.length == list?.length ? [] : list)
                }} type="checkbox" style={{ backgroundColor: "#FAFAFB", borderColor: "red", cursor: "pointer" }} />
            <Typography sx={{ color: "#625783", fontWeight: 400, fontSize: "16px", lineHeight: "19.2px" }}>
                {t('PROPERTIES_MANAGEMENT.SELECT_ALL')}
            </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", mb: 5 }}>
            {list.map((p: any, i: number) => {

                return <Box
                    onClick={() => {
                        if (!p.isConncted) {
                            setSelectedProperty((prev) => {
                                if (isSelecedProperty(p.id)) {
                                    return prev.filter((i) => i.id != p.id);
                                } else {
                                    return [...prev, p];
                                }
                            });
                        }
                    }}
                    sx={{ cursor: 'pointer' }} >
                    <RenderProperty image={StaticImage} name={p?.title} location={p?.street} onClick={() => { }} isSelected={p?.isConncted || isSelecedProperty(p.id)} />
                </Box>
            })}

        </Box>

        <Box onClick={() => {
            if (!selectedProperty?.length) return
            onLink(selectedProperty)
        }} sx={{ display: "flex", alignItems: "center", justifyContent: "center", mx: "20%", mb: 3 }}>
            <Box sx={{ width: "200px", height: "41px", borderRadius: "8px", backgroundColor: selectedProperty?.length ? "#5B3FFF" : 'gray', display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                <Typography sx={{ color: "#FFFFFF", fontWeight: 700, fontSize: "14px", lineHeight: "16.8px" }}>
                    {t('PROPERTIES_MANAGEMENT.LINK_SELECTED_PROPERTIES')}
                </Typography>
            </Box>
        </Box>

        <RenderSuccess title={t('PROPERTIES_MANAGEMENT.YOU_CAN_CHOOSE_MORE')} />
        <RenderSuccess title={t('PROPERTIES_MANAGEMENT.YOU_CAN_ADD_MORE_PROPERTIES')} />
        <RenderSuccess title={t('PROPERTIES_MANAGEMENT.START_MANAGMENT')} />

    </Box>
}