// @ts-nocheck

import { Box, Typography, Divider, CircularProgress } from "@mui/material";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AirbnbLogo from '@assets/svg/AirbnbLogo.svg?react';
import Valid from '@assets/svg/Valid.svg?react';
import BookingLogo from '@assets/svg/BookingLogo.svg?react';
import EnterId from '@assets/svg/Enter_id.svg?react';

import { memo, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useProperty } from "@services";
import { ToastMessageContext } from "@context/toast-message-cotnext";
import { t } from "i18next";
import { useIsMutating } from '@tanstack/react-query';




const StaticImage = "https://a0.muscache.com/im/pictures/miso/Hosting-1113684001326676721/original/0b7671c8-41ae-4d31-8db3-d9ee6d7d29be.jpeg?aki_policy=x_medium";

export const BookingEnterIDPure = ({ onClose = () => { }, list = [], propertiesList = [], onNext = () => { } }) => {
    const { t } = useTranslation();
    const mutating = useIsMutating();
    const [atLeastOneChannelConnected, setAtLeastOneChannelConnect] = useState(false)

    return <Box>
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box>
                <Typography sx={{ fontWeight: 700, fontSize: "24px", lineHeight: "28.8px", color: "#331860" }}>
                    {t('PROPERTIES_MANAGEMENT.ENTER_BOOKING_ID')}
                </Typography>
                <Typography sx={{ fontWeight: 400, fontSize: "14px", lineHeight: "16.8px", color: "#625783" }}>
                    {t('PROPERTIES_MANAGEMENT.TO_COMPLETE-CONNECT')}
                </Typography>
            </Box>
            <Typography onClick={onClose} sx={{ fontWeight: 700, color: 'black', cursor: 'pointer' }} >
                x
            </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "90%" }}>
                {/* Row 1 */}
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", gap: 2, mb: 2 }}>
                    <Box sx={{ marginLeft: 1.5, width: "40%", height: "38px", borderRadius: "8px", border: "1px solid #F8FAFC", backgroundColor: "#F8FAFC", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <AirbnbLogo />
                        <Typography sx={{ fontWeight: "700", fontSize: "15px", lineHeight: "18px", color: "#697586", marginRight: 1 }}>Airbnb</Typography>
                    </Box>
                    <Box sx={{ width: "40%", height: "38px", borderRadius: "8px", border: "1px solid #F8FAFC", backgroundColor: "#F8FAFC", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <BookingLogo />
                        <Typography sx={{ fontWeight: "700", fontSize: "15px", lineHeight: "18px", color: "#697586", marginRight: 1 }}>Booking.com</Typography>
                    </Box>
                </Box>
                {
                    list.map((c: any, i: any) => {
                        let isArraryItem = Array.isArray(propertiesList?.filter((x: any, _) => x?.airbnbUnitId == c?.id)) && propertiesList?.filter((x: any, _) => x?.airbnbUnitId == c?.id).length ? true : false
                        const isPropertyLinkedWithBooking = isArraryItem ? propertiesList?.filter((x: any, _) => x?.airbnbUnitId == c?.id)[0]?.bookingUnitId?.length > 1 : false
                        return <Box key={i} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", gap: 2, mb: 2 }}>
                            <RenderImageBooking title={c?.title} image={c?.image} />
                            <RenderInput onSuccessLinking={() => {
                                setAtLeastOneChannelConnect(true)
                            }}
                                isPropertyLinkedWithBooking={isPropertyLinkedWithBooking}
                                propertyId={
                                    Array.isArray(propertiesList?.filter((x: any, _) => x?.airbnbUnitId == c?.id)) && propertiesList?.filter((x: any, _) => x?.airbnbUnitId == c?.id)?.length ?
                                        propertiesList?.filter((x: any, _) => x?.airbnbUnitId == c?.id)[0]?.propertyId : ''}
                            />
                        </Box>
                    })
                }




            </Box>

        </Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mx: "20%", mt: 4 }}>
            <Box
                onClick={() => {
                    if (!atLeastOneChannelConnected) return
                    if (mutating > 0) {
                        return
                    }
                    onNext()

                }}
                sx={{ width: "200px", height: "41px", borderRadius: "8px", backgroundColor: !atLeastOneChannelConnected ? 'gray' : "#5B3FFF", display: "flex", alignItems: "center", justifyContent: "center", cursor: atLeastOneChannelConnected ? "pointer" : "default" }}>
                <Typography sx={{ color: "#FFFFFF", fontWeight: 700, fontSize: "14px", lineHeight: "16.8px" }}>
                    {t('PROPERTIES_MANAGEMENT.NEXT')}
                </Typography>
            </Box>
        </Box>

        <Box sx={{ width: "100%", borderRadius: "14.29px", display: "flex", alignItems: "center", justifyContent: "center", mt: 6 }}>
            <EnterId style={{ width: "565.18px", height: "300px", borderRadius: "13.34px" }} />
        </Box>
    </Box>
}
export const BookingEnterID = memo(BookingEnterIDPure, () => true)
const RenderImageBooking = ({ image = StaticImage, title = "شقة مميزة في النرجس" }) => {
    return <Box sx={{ width: "40%", height: "38px", borderRadius: "8px", border: "1px solid #E9E9E7", display: "flex", alignItems: "center", mt: 2, padding: "10px" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box component="img" sx={{
                width: "63px",
                height: '30px',
                borderRadius: "1.9px",
                mx: 0.5,
            }}
                alt="Description of image"
                src={image} />
            <Typography sx={{ fontWeight: 700, fontSize: "14px", lineHeight: "16.8px", color: "#331860", marginRight: "4px" }}>
                {title}
            </Typography>
        </Box>
    </Box>
}

interface RenderInputProps {
    name?: string;
    placeholder?: string;
    propertyId?: string
    onSuccessLinking?: () => void
}

const RenderInput: React.FC<RenderInputProps> = ({ onSuccessLinking = () => { }, isPropertyLinkedWithBooking = false, propertyId = '', name = t('PROPERTIES_MANAGEMENT.ID_PROPERTY'), placeholder = t('PROPERTIES_MANAGEMENT.ENTER_ID_PROPERTY') }) => {
    const [value, setValue] = useState<string>("");
    const { showSuccess, showError } = useContext(ToastMessageContext)
    const [iseError, setIsError] = useState({
        syncError: false,
        idError: false
    })
    const { t } = useTranslation();



    const { connectAirbnbPropertyToBookingMutation, syncBookingPriceMutation } = useProperty();
    const { mutate: syncPrice, isPending: isSyncing } = syncBookingPriceMutation({
        onSuccess: () => {
            onSuccessLinking()
            setIsError({
                syncError: false,
                idError: false
            })

            // showSuccess(t('PROPERTIES_MANAGEMENT.SUCCESSFUL_SYNC'));
        },
        onError: () => {
            showError(t('PROPERTIES_MANAGEMENT.ERROR_SYNC'));
            setIsError({
                syncError: true,
                idError: false
            })
        }
    })

    const { mutate, isPending, isSuccess } = connectAirbnbPropertyToBookingMutation({
        onSuccess: () => {
            //showSuccess(t('PROPERTIES_MANAGEMENT.SUCCESSFUL_LINKNIG'));

            syncPrice({
                bookingId: '',
                propertyId
            })
            setIsError({
                syncError: false,
                idError: false
            })

        },
        onError: (e: any) => {
            setIsError({
                syncError: false,
                idError: true
            })
            // showError(Array.isArray(e?.response?.data?.errorMessages) ? e?.response?.data?.errorMessages[0]?.messageEn : "رقم عقار غير صالح");
        }
    })

    // Function to handle input change
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setValue(inputValue);
        if(inputValue==''){
            setIsError({
                syncError: false,
                idError: false
            })
        }
    };


    const isNumeric = value && !isNaN(Number(value));


    return <Box sx={{ display: 'flex', alignItems: 'center', width: '40%', flexDirection: 'column', mt: 2 }}>
        <TextField
            disabled={isPropertyLinkedWithBooking || isSuccess}
            fullWidth
            value={value}
            onChange={handleChange}  // Bind handleChange function with event
            placeholder={isSuccess || isPropertyLinkedWithBooking ? (value ?? 'تم الربط العقار') : placeholder}
            onBlur={() => {
                value.length && mutate({
                    hotelId: value,
                    propertyId
                })
            }}
            sx={{
                '& .MuiOutlinedInput-root': {
                    borderRadius: 3,
                    backgroundColor: '#f8fafc',
                    border: iseError.idError || iseError.syncError ? '0.2px solid red' : undefined
                },
                '& .MuiInputBase-input': {
                    padding: '10px 14px',
                    fontSize: '16px',
                    color: '#4754678F',
                    fontWeight: 400,
                    lineHeight: "19.2px",

                },
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                                variant="body2"
                                sx={{ fontWeight: 400, color: '#475467', whiteSpace: 'nowrap', fontSize: "16px" }}
                            >
                                {name}
                            </Typography>
                            <Divider
                                orientation="vertical"
                                flexItem
                                sx={{
                                    height: 40,
                                    mx: 1,
                                    borderColor: '#e0e0e0',
                                }}
                            />
                        </Box>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        {(isSuccess || isPropertyLinkedWithBooking) && <Valid color="#16AE26" />}
                        {(isPending || isSyncing) && <CircularProgress size="18px" />}
                    </InputAdornment>
                )
            }}
        />
        {iseError.idError || iseError.syncError ? <Typography sx={{ color: 'red', fontWeight: 700, textAlign: 'right', width: '100%', mt: 0.3, fontSize: '12px' }} >

            {iseError.idError ? '  خطا في رمز العقار' : 'خطا في المزامنة'}
        </Typography> : null}

    </Box>
}

